import React, { useLayoutEffect, useContext } from "react"

import Layout from "../components/layout"

import { LangContext } from "../context/LangContext"


import { Container, Section } from "../components/anti/grid/grid"
import { CoreHeading } from "../components/gutenberg/core-heading"
import { replaceUnicode } from "../components/anti/utils/utils"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const TncTemplate = ({ pageContext }) => {
  const { lang, setLang } = useContext(LangContext)
  const wp = pageContext.data.blocks
  const wpID = pageContext.data.translation && pageContext.data.translation.blocks


  useLayoutEffect(() => {
    const navbar = document.querySelector(".navbar")
    navbar.classList.remove("transparent")
    navbar.classList.replace("dark", "light")
  }, [])

  return (
    <Layout>
      <Section>
        <Container>
          <h1 className="mb-5">{replaceUnicode(pageContext.data.title)}</h1>
          {lang == "ID" && wpID && 
            wpID.map((block, i) => {
              switch (block.name) {
                case "core/heading":
                  return <CoreHeading data={block} key={`${block.name}${i}`} />
                case "core/paragraph":
                  return (
                    <p
                      key={`${block.name}${i}`}
                      dangerouslySetInnerHTML={{
                        __html: block.attributes.content,
                      }}
                    />
                  )
                default:
                  break
              }
          })}
          {lang == "ID" && wpID == null &&  
            wp.map((block, i) => {
              switch (block.name) {
                case "core/heading":
                  return <CoreHeading data={block} key={`${block.name}${i}`} />
                case "core/paragraph":
                  return (
                    <p
                      key={`${block.name}${i}`}
                      dangerouslySetInnerHTML={{
                        __html: block.attributes.content,
                      }}
                    />
                  )
                default:
                  break
              }
          })}
          {wp.map((block, i) => {
            switch (block.name) {
              case "core/heading":
                return <CoreHeading data={block} key={`${block.name}${i}`} />
              case "core/paragraph":
                return (
                  <p
                    key={`${block.name}${i}`}
                    dangerouslySetInnerHTML={{
                      __html: block.attributes.content,
                    }}
                  />
                )
              default:
                break
            }
          })}
        </Container>
      </Section>
    </Layout>
  )
}

export default TncTemplate
